
import {
  computed,
  defineComponent,
  getCurrentInstance,
  onMounted,
  ref,
  watch,
} from "vue";
import { Upload } from "@element-plus/icons-vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { ApiBase, ApiMerchant, ApiSalesOrders } from "@/core/api";
import JwtService from "@/core/services/JwtService";
import mixin from "@/mixins";
import { useI18n } from "vue-i18n";
import _ from "lodash";
import {
  TaggingItem,
  AddressOption,
  ChannelOption,
  defaultAddressData,
} from "@/core/directive/interface/common";
import { ElUpload } from "element-plus";
import { ElFile } from "element-plus/es/components/upload/src/upload.type";
import CommonChannelOption from "@/components/layout/CommonChannelOption.vue";
import { removeReadonly } from "@/core/directive/function/common";
import { User } from "@/store/modules/AuthModule";
import store from "@/store";
import { getOwnerLabel } from "@/core/directive/function/user";
import router from "@/router";

export default defineComponent({
  name: "service_orders_import-modal",
  props: {
    type: {
      type: Number,
      default: 0, // 0: sales order 1: double sale
    },
    subType: {
      type: Number,
      default: 0, // 0: sales order order overview 1: sales order order verification
    },
    checkValue: { type: Number, default: 0, required: true },
  },
  components: { CommonChannelOption },
  setup(props, { emit }) {
    const {
      showValidateErrorMsg,
      showFormSubmitSuccessMsg,
      showServerErrorMsg,
    } = mixin();

    const { t } = useI18n();
    const currentInstance: any = getCurrentInstance();

    const formRef = ref<null | HTMLFormElement>(null);
    const loading = ref<boolean>(false);
    const uploadRef = ref<InstanceType<typeof ElUpload>>();

    const formData = ref({
      request_merchant_id: "",
      request_channel_id: "",
      import_order_config_id: props.checkValue,
      customer_id: "",
      billing_address_id: "",
      billing_address: "",
      shipping_address_id: "",
      shipping_address: "",
      importFileList: [],
      importFile: {} as ElFile,
    });

    const rules = ref({
      request_merchant_id: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      request_channel_id: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      import_order_config_id: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      customer_id: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      billing_address_id: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      shipping_address_id: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      importFileList: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
    });

    const options = ref({
      merchant: [] as TaggingItem[],
      merchantLoading: false,
      channel: [] as ChannelOption[],
      channelLoading: false,
      import_order_config_id: [] as TaggingItem[],
      customerLoading: false,
      customer_id: [] as TaggingItem[],
      billingAddressLoading: false,
      billingAddressData: Object.assign({}, defaultAddressData),
      billing_address_id: [] as AddressOption[],
      shippingAddressLoading: false,
      shipping_address_id: [] as AddressOption[],
      shippingAddressData: Object.assign({}, defaultAddressData),
    });

    const userInfo = ref<User>(store.getters.currentUser);

    watch(
      () => store.getters.currentUser,
      (newVal) => {
        userInfo.value = newVal;
      }
    );

    const cancelReadonly = (async) => {
      removeReadonly(async, currentInstance);
    };

    const uploadUrl = ApiSalesOrders.importOrderManager();

    const uploadHeaders = ref({
      Authorization: `Bearer ${JwtService.getToken()}`,
    });

    const uploadData = computed(()=> {
      return {
          ...formData.value,
          owner_id: userInfo.value.settlement_default_owner,
        }
      // if (props.type === 1) {
      //   return {
      //     ...formData.value,
      //     owner_id: userInfo.value.settlement_default_owner,
      //   }
      // }
      // return {
      //   ...formData.value,
      // }
    })

    const setErrorUploadFile = () => {
      uploadRef.value!.clearFiles();
      uploadRef.value!.handleStart(formData.value.importFile);
    };

    const handleUploadError = () => {
      loading.value = false;
      setErrorUploadFile();
    };

    const handleUploading = () => {
      loading.value = true;
    };

    const handleUploadChange = (file, files) => {
      formData.value.importFileList = files;
      formData.value.importFile = file.raw;
    };

    const handleUploadExced = (files, uploadFiles) => {
      uploadRef.value!.handleRemove(uploadFiles[0], uploadFiles[0].raw);
      uploadRef.value!.clearFiles();
      uploadRef.value!.handleStart(files[0]);
    };

    const handleUploadRemove = (file, files) => {
      formData.value.importFileList = [];
      formData.value.importFile = {} as ElFile;
    };

    const handleUploadSuccess = (res, file) => {
      loading.value = false;
      if (res.code == 0) {
        Swal.fire({
          text: t("salesOrder.importSuccess"),
          icon: "success",
          buttonsStyling: false,
          confirmButtonText: t("common.okGotIt"),
          customClass: {
            confirmButton: "btn btn-primary",
          },
        }).then(() => {
          router.replace(goPath.value);
        });
      } else {
        showServerErrorMsg(res);
        setErrorUploadFile();
      }
    };

    const getMerchantSourceData = async (value) => {
      options.value.merchantLoading = true;
      let params = {
        search_value: value,
      };
      const { data } = await ApiBase.getMerchantSourceData({
        ...params,
      });
      options.value.merchantLoading = false;
      if (data.code == 0) {
        options.value.merchant = data.data;
      }
    };

    const debounceMerchantSourceSearch = _.debounce(
      getMerchantSourceData,
      1000
    );

    const searchMerchantSourceItems = (query: string) => {
      debounceMerchantSourceSearch(query);
    };

    const getSalesChannelsData = async (id) => {
      formData.value.request_channel_id = "";
      options.value.channel = [];
      if (id) {
        options.value.channelLoading = true;
        const { data } = await ApiMerchant.getSalesChannelsData({
          max_item: "all",
          merchant_id: id,
          status: "10",
        });
        options.value.channelLoading = false;
        if (data.code == 0) {
          options.value.channel = data.data;
        }
      }
    };

    const getOrderConfigOptions = async () => {
      loading.value = true;
      const { data } = await ApiSalesOrders.getOrderConfigOptions({});
      loading.value = false;
      if (data.code == 0) {
        options.value.import_order_config_id = data.data;
      }
    };

    const getCustomerOptions = async (query: string) => {
      options.value.customerLoading = true;
      const { data } = await ApiBase.getCustomerSourceData({ name: query });
      options.value.customerLoading = false;
      if (data.code == 0) {
        options.value.customer_id = data.data;
      }
    };
    

    const debounceCustomerSearch = _.debounce(getCustomerOptions, 1000);

    const searchCustomerItems = (query: string) => {
      debounceCustomerSearch(query);
    };

    const userChange = (e) => {
      formData.value.billing_address_id = "";
      formData.value.shipping_address_id = "";
      formData.value.billing_address = "";
      formData.value.shipping_address = "";
      options.value.billingAddressData = Object.assign({}, defaultAddressData);
      options.value.shippingAddressData = Object.assign({}, defaultAddressData);
      getOcpsCustomersBillingAddress(e);
      getOcpsCustomersShippingAddress(e);
    };

    const getOcpsCustomersBillingAddress = async (value: string) => {
      options.value.billingAddressLoading = true;
      const { data } = await ApiBase.getOcpsCustomersBillingAddress({
        user_id: value,
      });
      options.value.billingAddressLoading = false;
      if (data.code == 0) {
        options.value.billing_address_id = data.data;
      }
    };

    const debounceBillingAddressSearch = _.debounce(
      getOcpsCustomersBillingAddress,
      1000
    );

    const searchBillingAddressItems = (value: string) => {
      debounceBillingAddressSearch(value);
    };

    const getOcpsCustomersShippingAddress = async (value: string) => {
      options.value.shippingAddressLoading = true;
      const { data } = await ApiBase.getOcpsCustomersShippingAddress({
        user_id: value,
      });
      options.value.shippingAddressLoading = false;
      if (data.code == 0) {
        options.value.shipping_address_id = data.data;
      }
    };

    const debounceShippingAddressSearch = _.debounce(
      getOcpsCustomersShippingAddress,
      1000
    );

    const searchShippingAddressItems = (value: string) => {
      debounceShippingAddressSearch(value);
    };

    const addressChange = (e, type: string) => {
      if (type == "billing") {
        if (e == "") {
          formData.value.billing_address_id = "";
          options.value.billingAddressData = Object.assign(
            {},
            defaultAddressData
          );
        } else {
          formData.value.billing_address_id = e.value;
          options.value.billingAddressData = e.data;
        }
      } else {
        if (e == "") {
          formData.value.shipping_address_id = "";
          options.value.shippingAddressData = Object.assign(
            {},
            defaultAddressData
          );
        } else {
          formData.value.shipping_address_id = e.value;
          options.value.shippingAddressData = e.data;
        }
      }
    };

    const handleDiscard = () => {
      resetForm();
    };

    const submit = () => {
      if (!formRef.value) {
        return;
      }
      formRef.value.validate((valid) => {
        if (loading.value) return;
        if (valid) {
          if (loading.value) return;
          Swal.fire({
            text: t("salesOrderOverview.operatingEntitySubmitTip", [
              getOwnerLabel.value(userInfo.value.settlement_default_owner),
            ]),
            icon: "question",
            iconHtml: "!",
            showCancelButton: true,
            buttonsStyling: false,
            confirmButtonText: t("salesOrderOverview.operatingEntityYes"),
            cancelButtonText: t("salesOrderOverview.operatingEntityNo"),
            customClass: {
              confirmButton: "btn btn-primary",
              cancelButton: "btn btn-light",
            },
          }).then((result) => {
            if (result.isConfirmed) {
              loading.value = true;
              uploadRef.value!.submit();
            }
          });
        } else {
          showValidateErrorMsg();
          return false;
        }
      });
    };

    const resetForm = () => {
      formRef.value?.resetFields();
      uploadRef.value!.clearFiles();
      formData.value.billing_address = "";
      formData.value.shipping_address = "";
      options.value.billingAddressData = Object.assign({}, defaultAddressData);
      options.value.shippingAddressData = Object.assign({}, defaultAddressData);
    };

    const goPath = computed(() => {
      if (props.type === 0) {
        if (props.subType === 0) {
          return "/sales-order/order-overview";
        } else {
          return "/sales-order/order-verification";
        }
      } else {
        return "/double-sale-order/order-overview";
      }
    });

    onMounted(() => {
      getOrderConfigOptions();
      cancelReadonly(false);
    });

    return {
      t,
      getOwnerLabel,
      props,
      loading,
      formRef,
      uploadRef,
      formData,
      rules,
      options,
      userInfo,
      cancelReadonly,
      searchMerchantSourceItems,
      getSalesChannelsData,
      searchCustomerItems,
      userChange,
      searchBillingAddressItems,
      searchShippingAddressItems,
      handleUploadError,
      handleUploading,
      handleUploadChange,
      handleUploadExced,
      handleUploadRemove,
      handleUploadSuccess,
      uploadUrl,
      uploadHeaders,
      uploadData,
      Upload,
      addressChange,
      handleDiscard,
      submit,
      resetForm,
      goPath,
    };
  },
});
